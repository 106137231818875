import React from 'react'
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import logo from '../images/icon2.1.png'

const Navbar = ({ location }) => {
  // console.log(location);
  return (
    <div className="section">
      <div className="container">
        <div className="navbar-wrapper">
          <Link to="/" className="name" tabIndex={0}>
            <img width={50} src={logo} alt="icons"></img>
          </Link>
          <div className="links-wrapper">
            {location?.pathname.includes('/blog') ? (
              <>
                <Link to="/#work">Portfolio</Link>
                <Link to="/blog">Blog</Link>
                <Link to="/#skills">Skills</Link>
                <Link to="/#contact">Contact</Link>
              </>
            ) : (
              <>
                <button
                  onClick={() => scrollTo('#work')}
                  onKeyDown={() => scrollTo('#work')}
                >
                  Portfolio
                </button>
                {/* <Link to="/blog">Blog</Link> */}
                <button
                  onClick={() => scrollTo('#skills')}
                  onKeyDown={() => scrollTo('#skills')}
                >
                  Skills
                </button>
                <button
                  onClick={() => scrollTo('#contact')}
                  onKeyDown={() => scrollTo('#contact')}
                >
                  Contact
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
